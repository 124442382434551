//@ts-check
import december from '../../assets/images/devotional/december-2022.jpg';
import marchx from '../../assets/images/devotional/march-2023.jpg';
import october from '../../assets/images/devotional/october-2022.jpg';
import OCTOBER2024 from '../../assets/images/devotional/OCTOBER2024.jpg';
import september from '../../assets/images/devotional/september-2022.jpg';
import september24 from '../../assets/images/devotional/september-2024.jpg';

const catalog = [
    {
        link: "https://bit.ly/gec-devotional-2-2025",
        title: 'Devotional (February 2025)',
        month: 'February 2025',
        fileSize: '1.79 MB',
        cover: 'https://firebasestorage.googleapis.com/v0/b/gec-podcast.appspot.com/o/gec%2Fdevotional%2Fcover%2FFEBRUARY%20g.jpg?alt=media&token=a4a6a763-921b-4af9-b711-6ff985ec435a',
        highlights: [
            'His Leading',
            'Spiritual reminders',
            'Arise and Shine',
            "The way of Faith", 
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-1-2025",
        title: 'Devotional (January 2025)',
        month: 'January 2025',
        fileSize: '1.79 MB',
        cover: 'https://firebasestorage.googleapis.com/v0/b/gec-podcast.appspot.com/o/gec%2FPodcast%2Fimage%2FJANUARY%20E.jpg?alt=media&token=dab9e338-1be2-474f-9a87-890b12ab2a69',
        highlights: [
            'Government',
            'Truths in Christ',
            'Secrets of God\'s house',
            "God of the broken",
            "The Holy Spirit"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-12-2024",
        title: 'Devotional (December 2024)',
        month: 'December 2024',
        fileSize: '1.79 MB',
        cover: 'https://firebasestorage.googleapis.com/v0/b/gec-podcast.appspot.com/o/gec%2Fdevotional%2Fcover%2Fdecember-2024.jpg?alt=media&token=f3a2c355-627b-4982-9a42-c3f97090efc3',
        highlights: [
            'Don t give up',
            'Trusting the lord',
            'Serving the lord',
            "Salvation by Grace alone",
            "Glory to God"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-11-2024",
        title: 'Devotional (November 2024)',
        month: 'November 2024',
        fileSize: '1.79 MB',
        cover: 'https://firebasestorage.googleapis.com/v0/b/gec-podcast.appspot.com/o/gec%2Fdevotional%2Fcover%2FNOVEMBER24.jpg?alt=media&token=2b28f872-41af-4dce-b2ce-600eca1da2f8',
        highlights: [
            'Some instructions of wisdom',
            'His Presence',
            'No condemnation',
            "The secret access to power",
            "Wisdom"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-10-2024",
        title: 'Devotional (October 2024)',
        month: 'October 2024',
        fileSize: '1.43 MB',
        cover: OCTOBER2024,
        highlights: [
            'Understanding salvation',
            'Let us go to church ',
            'Drawing from the Spirit',
            "The strong Force of Blessings"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-9-2024",
        title: 'Devotional (September 2024)',
        month: 'September 2024',
        fileSize: '1.43 MB',
        cover: september24,
        highlights: [
            'Christian growth',
            'Boldness',
            'This present darkness',
            "What young people should do"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-8-2024",
        title: 'Devotional (August 2024)',
        month: 'August 2024',
        fileSize: '1.43 MB',
        cover: december,
        highlights: [
            'Being rooted and built up',
            'God is faithful',
            'Growing in the word',
            "Faith"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-7-2024",
        title: 'Devotional (July 2024)',
        month: 'July 2024',
        fileSize: '1.43 MB',
        cover: december,
        highlights: [
            'The mighty Word of God',
            'Preach the Gospel',
            'Men ought to pray',
            "How God preserves us"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-6-2024",
        title: 'Devotional (June 2024)',
        month: 'June 2024',
        fileSize: '1.43 MB',
        cover: december,
        highlights: [
            'Healing belongs to you',
            'Spiritual effectiveness',
            'A deep walk with God',
            "Go therefore"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-5-2024",
        title: 'Devotional (May 2024)',
        month: 'May 2024',
        fileSize: '2.03 MB',
        cover: december,
        highlights: [
            'The Lord Hears',
            'Grace - The Beauty of God',
            'The Truths of this Life',
            "Forgiveness"
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-4-2024",
        title: 'Devotional (April 2024)',
        month: 'April 2024',
        fileSize: '2.03 MB',
        cover: december,
        highlights: [
            'The way of Faith',
            'The Grace of our Lord Jesus Christ',
            'The Power of God',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-3-2024",
        title: 'Devotional (March 2024)',
        month: 'March 2024',
        fileSize: '1.79 MB',
        cover: december,
        highlights: [
            'Arise and shine',
            'Training for reigning',
            'The harvest',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-2-2024",
        title: 'Devotional (February 2024)',
        month: 'February 2024',
        fileSize: '2.74 MB',
        cover: december,
        highlights: [
            'Authority of the word of god',
            'When riches pulled',
            'Our walk with god',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-1-2024",
        title: 'Devotional (January 2024)',
        month: 'January 2024',
        fileSize: '2.74 MB',
        cover: october,
        highlights: [
            'Faith',
            'Overcoming doubt',
            'Truths of life',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-12-2023",
        title: 'Devotional (December 2023)',
        month: 'December 2023',
        fileSize: '0.58 MB',
        cover: october,
        highlights: [
            'Life in Christ',
            'Secrets of God\'s house',
            'God with us',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-11-2023",
        title: 'Devotional (November 2023)',
        month: 'November 2023',
        fileSize: '1.48 MB',
        cover: october,
        highlights: [
            'Understanding salvation',
            'Mysteries of a mustard seed',
            'The strong force of blessings',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-10-2023",
        title: 'Devotional (October 2023)',
        month: 'October 2023',
        fileSize: '1.48 MB',
        cover: october,
        highlights: [
            'Understanding salvation',
            'Mysteries of a mustard seed',
            'The strong force of blessings',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-9-2023",
        title: 'Devotional (September 2023)',
        month: 'September 2023',
        fileSize: '1.48 MB',
        cover: marchx,
        highlights: [
            'Knowing the Holy Spirit',
            'Drawing from the Spirit',
            'Forgiveness',
        ]
    },
    {
        link: "https://bit.ly/gec-devotional-8-2023",
        title: 'Devotional (August 2023)',
        month: 'August 2023',
        fileSize: '1.48 MB',
        cover: december,
    },
    {
        link: "https://bit.ly/gec-devotional-7-2023",
        title: 'Devotional (July 2023)',
        month: 'July 2023',
        fileSize: '1.48 MB',
        cover: marchx,
    },
    {
        link: "https://bit.ly/gec-devotional-6-2023",
        title: 'Devotional (June 2023)',
        month: 'June 2023',
        fileSize: '1.48 MB',
        cover: december,
    },
    {
        link: "https://bit.ly/gec-devotional-5-2023",
        title: 'Devotional (May 2023)',
        month: 'May 2023',
        fileSize: '1.58 MB',
        cover: marchx,
    },
    {
        link: "https://bit.ly/gec-devotional-4-2023",
        title: 'Devotional (Apr 2023)',
        month: 'April 2023',
        fileSize: '1.58 MB',
        cover: october,
    },
    {
        link: "https://bit.ly/gec-devotional-3-2023",
        title: 'Devotional (Mar 2023)',
        month: 'March 2023',
        fileSize: '1.77 MB',
        cover: marchx,
    },
    {
        link: "https://bit.ly/gec-devotional-2-2023",
        title: 'Devotional (Feb 2023)',
        month: 'February 2023',
        fileSize: '800 KB',
        cover: december,
    },
    {
        link: "https://bit.ly/gec-devotional-1-2023",
        title: 'Devotional (Jan 2023)',
        month: 'January 2023',
        fileSize: '1.03 MB',
        cover: october,
    },
    {
        link: "https://bit.ly/gec-devotional-12-2022",
        title: 'Devotional (Dec 2023)',
        month: 'December 2022',
        fileSize: '<1.03 MB',
        cover: december
    },
    {
        link: "https://bit.ly/gec-devotional-11-2022",
        title: 'Devotional (Nov 2023)',
        month: 'November 2022',
        fileSize: '<1.00 MB',
        cover: september
    },
    {
        link: "https://bit.ly/gec-devotional-10-2022",
        title: 'Devotional (Oct 2023)',
        month: 'October 2022',
        fileSize: '<1.03 MB',
        cover: october
    },
    {
        link: "https://firebasestorage.googleapis.com/v0/b/gec-podcast.appspot.com/o/gec%2Fdevotional%2FSept.22%2CPDF%20Version.pdf?alt=media&token=352e61af-6465-4f90-9a74-d9315ed241d1",
        title: 'Devotional (September 2023)',
        month: 'September 2022',
        fileSize: '<1.03 MB',
        cover: december
    },
]

export default catalog;